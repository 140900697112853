<template>
  <div>
    <datatable
      ref="table"
      :url="orderHistoryResource.url"
      :ajax="true"
      :ajax-pagination="true"
      :columns="orderHistoryResource.columns"
      :fillable="false"
      :loading="orderHistoryResource.loading"
      :limit="15"
    />
  </div>
</template>

<script>
export default {
  props: {
    staff: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      orderHistoryResource: this.$options.resource([], {
        url: `${this.$baseurl}/admin/personal/users/${this.staff?.id}/order-transactions`,
        columns: [
          {
            name: "order_no",
            th: "Order No",
          },
          {
            name: "item",
            th: "Item",
          },
          {
            name: "merchant",
            th: "Merchant",
            render: (order) => `${order?.merchant?.business_name}`,
          },
          {
            name: "branch",
            th: "Branch",
            render: (order) => `${order?.branch?.name}`,
          },
          {
            name: "amount",
            th: "Amount",
            render: (order) =>
              `${
                this.$options.filters.formatAmount(
                  order?.loan?.amount ?? 
                  order?.transaction?.amount ?? 
                  order?.amount
                )
              }`,
          },
          {
            name: "status",
            th: "Payment Status",
            render: (order, status) => this.statusCheck(status),
          },
          {
            name: "created_at",
            th: "Date",
            render: (order, created_at) =>
              this.$options.filters.dateFormat(created_at, "D, dd M Y h:ia"),
          },
        ],
      }),
    };
  },
  methods: {
    statusCheck(status) {
      switch (status) {
        case "success":
        case "delivered":
        case "disbursed":
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                    ${status}
                  </div>`;
        case "failed":
        case "cancelled":
          return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
                    ${status}
                  </div>`;
        case "processing":
        case "confirmed":
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-3 py-2 inline-block">
                    ${status}
                  </div>`;
        case "pending":
        default:
          return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                    ${status}
                  </div>`;
      }
    },
  },
};
</script>
